import {
  all,
  call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { viewGroupActions } from './slice';
import API from '../../constant';

import {
  IActivateGroup, IPaginateFilterPayload,
} from './interface';

export function* viewGroupGenerator({ payload }: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GROUP_BY_ENT.path}/?keyword=${payload.keyword}&status=${payload.status}&page=${payload.page}&limit=${payload.limit}&groupType=${payload.type}`, method: API.GET_GROUP_BY_ENT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGroupActions.getGroupsSucceeded({
        groups: response.data?.result,
        total: response.data?.total,
      }));
    } else {
      yield put(viewGroupActions.getGroupsFailed());
    }
  } catch (error) {
    yield put(viewGroupActions.getGroupsFailed());
  }
}

export function* editActiveGenerator({ payload }: PayloadAction<IActivateGroup>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.PATCH_UPDATE_GROUP.path.replace(':id', payload.id),
        method: API.PATCH_UPDATE_GROUP.method,
      },
      {
        isActive: payload.isActive,
      },
      true,
    );
    if (response.statusCode === 200) {
      yield put(viewGroupActions.editActiveGroupSucceeded(payload));
      yield put(viewGroupActions.closeModal(payload.id));
    } else {
      yield put(viewGroupActions.editActiveGroupFailed());
    }
  } catch (error) {
    yield put(viewGroupActions.editActiveGroupFailed());
  }
}
export function* deleteGroupGenerator({ payload }: PayloadAction<string>): any {
  try {
    const id = payload;
    const response: IRestApiResponse = yield call(
      request,
      API.DELETE_GROUP,
      { id },
      true,
    );
    if (response.statusCode === 202) {
      yield put(viewGroupActions.deleteGroupSucceeded(id));
      yield put(viewGroupActions.closeModal(payload));
    } else {
      yield put(viewGroupActions.deleteGroupFailed());
    }
  } catch (error) {
    yield put(viewGroupActions.deleteGroupFailed());
  }
}

export function* viewGroupSaga() {
  yield all([takeLatest(viewGroupActions.getGroups.type, viewGroupGenerator),
    takeLatest(viewGroupActions.deleteGroup.type, deleteGroupGenerator),
    takeLatest(viewGroupActions.editActiveGroup.type, editActiveGenerator),
  ]);
}

export default viewGroupSaga;
