/* eslint-disable import/no-cycle */
import { ArticleDocument } from '../articles/interface';
import { SessionDocument } from '../experts/components/edit-expert/components/sessions/interface';
import { IExpert } from '../experts/components/view-experts/interface';
import { IExpertAvatar } from '../experts/interface';
import { IUserDocument } from '../users/interface';

export enum ModalStates {
  delete = 'DELETE',
  close = 'CLOSE',
  active = 'ACTIVE',
  generalInformation = 'GENERAL_INFORMATION',
  accountType = 'ACCOUNT_TYPE',
  sponsorContent = 'SPONSOR_CONTENT',
  addExpert = 'ADD_EXPERT',
  deleteExpert = 'DELETE_EXPERT',
  addSession = 'ADD_SESSION',
  deleteSession = 'DELETE_SESSION',
  hideSession = 'HIDE_SESSION',
  deleteMedia = 'DELETE_MEDIA',
  addArticle = 'ADD_Article',
  deleteArticle = 'DELETE_ARTICLE',
  bulkUsers = 'BULK_USERS',
  addUser = 'ADD_USER',
  addMedia = 'ADD_MEDIA',
  deleteUser = 'DELETE_USER',
}

export interface SponsorContent {
  url: string;
  contentUrl: string;
}
export interface GroupSessionDocument {
  session: string;
  isVisible: boolean;
}
export interface GroupContentDocument {
  content: string;
  isVisible: boolean;
}
export interface GroupArticleDocument {
  article: string;
  isVisible: boolean;
}

export interface ISession extends SessionDocument {
  isVisible: boolean;
  modalState: string;
}
export interface IArticle extends ArticleDocument {
  isVisible: boolean;
  modalState: string;
}
export interface GroupUserDocument {
  details: IUserDocument;
  email: string;
  joinedAt: string;
}

export interface IRejectedData {
  rejectedAt: string;
  rejectedBy: string;
  reason: string
}

export interface IUser extends IUserDocument {
  groupUserAttributes: {
    joinedAt: string;
    membershipStatus: string;
    dateRequested: string;
    rejectionHistory: IRejectedData[]
  }
  modalState: string;
}
export interface IGeneralInformationModalForm {
  title: string;
  subTitle: string;
  description: string;
  bannerImgUrl: string;
}
export interface IAccountTypeModalForm {
  groupType: string;
  groupVisibility: string;
  password: string;
  currency: string;
  subscriptionFee: number;
  subscriptionFrequency: string;
}

export interface ISponsorContentModalForm {
  sponsorContent: SponsorContent[];
}

export interface IAddUserModalForm {
  email: string;
}

export interface IResourceGroupCreationData {
  expertId: string,
  resourceType: 'article' | 'session' | 'content',
  resourceIds: string[]
}

export interface IExpertItemData {
  id: string;
  fullName: string;
  email: string;
  isActive: boolean;
  profileImgUrl: string;
  category: string;
  isAdded: boolean;
}
export interface ISessionItemData {
  id: string;
  title: string;
  sessionType: string;
  expertId: string;
  expert: IExpertAvatar;
  isActive: boolean;
  isAdded: boolean;
}
export interface IArticleItemData {
  id: string;
  title: string;
  author: string;
  authorName: string;
  isAdded: boolean;
}

export interface IOpenModal {
  id: string;
  state: string;
}
export interface IFilteredExperts {
  result: IExpert[];
  total: number;
}

export interface IFilteredSessions {
  result: ISession[];
  total: number;
}

export interface IFilteredUsers {
  result: IUser[];
  total: number;
}

export interface IFilteredArticles {
  result: IArticle[];
  total: number;
}

export interface IUserRequestPayload {
  userId: string;
  groupId: string;
  isAccepted: boolean;
}
export interface IExpertGroupDetails {
  expert: string;
  sessions: GroupSessionDocument[];
  contents: GroupContentDocument[];
  articles: GroupArticleDocument[];
  joinedAt: string;
}

export interface IGroupAvatar {
  uuid: string;
  title: string;
  bannerImgUrl: string;
  groupType?: string;
}

export interface IGroupAvatarWithAction extends IGroupAvatar {
  isAdded: boolean;
  id: string;
}

interface SubscriptionFrequency {
  subFrequency: string;
  valid: boolean;
}

export interface GroupDocument {
  uuid: string;
  entId: string;
  isActive: boolean;
  groupType: string;
  visibility: string;
  subscriptionFee: number;
  currency: string;
  subscribersCount: number;
  subscriptionFrequency: SubscriptionFrequency;
  title: string;
  subTitle: string;
  description: string;
  bannerImgUrl: string;
  groupAdmin: string;
  createdBy: string;
  isPinnedProduct: boolean;
  pinnedIndex: number;
  createdAt: string;
  updatedAt: string;
  deletedBy: string | null;
  deletedAt: string | null;
  sponsorContent: SponsorContent[];
  followersCount: number;
}
