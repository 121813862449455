/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateEntAccState, ICreateGeneralInformationForm,
  IAddUserAllocationForm, CreateEntAccStep,
  ICreateTenantPayload,
} from './interface';

export const initialState: CreateEntAccState = {
  active: true,
  loading: false,
  status: CreateEntAccStep.generalInformation,
  generalInformationForm: {
    name: '', entUrl: '', email: '', currency: 'AUD', country: 'Australia', city: 'Sydney', slocoachAdmin: '',
  },
  userAllocationForm: {
    maxNoOfGroups: 0,
    maxNoOfCoaches: 0,
    maxNoOfEndUsers: 0,
  },
  chargesForm: { adminFee: 0, stripePlatformRegion: 'AU' },

};

export const createEntAccSlice = createSlice({
  name: 'feature/ent-acc/create-ent-acc',
  initialState,
  reducers: {
    createEntAcc(state: CreateEntAccState, action:PayloadAction<ICreateTenantPayload>) {
      state.loading = true;
      state.chargesForm = action.payload;
    },
    createEntAccSucceeded(state: CreateEntAccState) {
      state.loading = false;
      state.status = CreateEntAccStep.successful;
    },
    createEntAccFailed(state: CreateEntAccState) {
      state.loading = false;
      state.status = CreateEntAccStep.charges;
    },
    AddGeneralInformation(
      state: CreateEntAccState,
      action:PayloadAction<ICreateGeneralInformationForm>,
    ) {
      state.generalInformationForm = action.payload;
      state.status = CreateEntAccStep.userAllocation;
    },
    AddUserAllocation(state: CreateEntAccState, action:PayloadAction<IAddUserAllocationForm>) {
      state.userAllocationForm = action.payload;
      state.status = CreateEntAccStep.charges;
    },

    updateActive(state: CreateEntAccState) {
      state.active = !state.active;
    },
    setStatus(state:CreateEntAccState, action:PayloadAction<number>) {
      state.status = action.payload;
    },
    reset: () => initialState,

  },
});

export const { actions: createEntAccActions } = createEntAccSlice;
