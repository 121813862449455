import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import {
  ErrorMessage,
  PrimaryLoadingButton,
  PrimaryNumericField,
  PrimarySelect,
} from 'components';
import '../../index.scss';
import { IOption } from 'interfaces';
import { getOptionsFromEnum } from 'utils/data-utils';
import { StripePlatformRegion } from 'config/stripe';
import { Typography } from '@mui/material';
import { IAddChargesForm } from '../../../../interface';

interface Props {
  onSubmit: SubmitHandler<any>;
  loading: boolean
}

const ChargeForm = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IAddChargesForm>();

  const { onSubmit, loading } = props;

  const stripeRegions: IOption[] = getOptionsFromEnum(StripePlatformRegion);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item"> Charges</h3>
      <div className="form-item">
        <Controller
          control={control}
          name="adminFee"
          render={({ field }) => (
            <PrimaryNumericField
              {...field}
              min={0}
              max={100}
              label="Admin fee %"
            />
          )}
        />
        <ErrorMessage>{errors?.adminFee?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="stripePlatformRegion"
          render={({ field }) => (
            <PrimarySelect
              {...field}
              label="Stripe platform region"
              placeholder="Select region"
              options={stripeRegions}
            />
          )}
        />
        <ErrorMessage>{errors?.stripePlatformRegion?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <Typography variant="body1">
          Stripe platform region is the region where the tenant,
          expert and user stripe accounts will be created.
          User credit cards also will be stored in this region.
          This region cannot be changed once the account is created.
        </Typography>
      </div>
      <div>
        <PrimaryLoadingButton loading={loading} size="large" type="submit" variant="contained">
          Create enterprise account
        </PrimaryLoadingButton>
      </div>

    </form>
  );
};

export default ChargeForm;
