const API = {
  GET_ENT_ACC: {
    path: '/ent-account/details',
    method: 'GET',
  },
  GET_GIFT_CARD_BY_ENT: {
    path: '/giftcard/details/ent',
    method: 'GET',
  },
  GET_SESSIONS_BY_ENT: {
    path: '/session/sort-search',
    method: 'GET',
  },
  GET_EXPERTS_BY_ENT: {
    path: '/expert/search',
    method: 'GET',
  },
  GET_GROUPS_BY_ENT: {
    path: '/groups/search',
    method: 'GET',
  },
  GET_ARTICLES_BY_ENT: {
    path: '/article/search',
    method: 'GET',
  },
  POST_STRIPE_CONNECT_URL: {
    path: '/stripe/connect/generate-url',
    method: 'POST',
  },
  POST_ENT_ACC: {
    path: '/ent-account/update',
    method: 'POST',
  },
  POST_ENT_PRODUCTS: {
    path: '/ent-account/products',
    method: 'POST',
  },
  POST_GIFT_CARD: {
    path: '/giftcard/create',
    method: 'POST',
  },
  DELETE_ENT_ACC: {
    path: '/ent-account/delete',
    method: 'DELETE',
  },

  DELETE_GIFT_CARD: {
    path: '/giftcard/delete',
    method: 'DELETE',
  },
  GET_CATEGORIES: {
    path: '/categories',
    method: 'GET',
  },
  POST_CATEGORIES: {
    path: '/categories?allowBulk=true',
    method: 'POST',
  },
};
export default API;
