/* eslint-disable no-underscore-dangle */
import {
  all,
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { selectGeneralInformation } from 'pages/dashboard/components/ent-account/components/edit-account/selector';
import { currencyFormat } from 'utils/formats';
import { SessionType, SessionAudienceTypes } from 'config';
import { coreSliceActions } from 'base/core/slice';
import API from './constants';
import {
  selectEntId,
  selectExpertIds,
  selectId,
} from './selector';
import {
  IActive,
  IPaginateFilterPayload,
  ItemDetailsMap,
  IUpdateGroupExpertResourcesDto,
} from './interface';
import { editGroupActions } from './slice';
import {
  IAccountTypeModalForm,
  IArticleItemData,
  IExpertItemData,
  IGeneralInformationModalForm,
  ISessionItemData,
  ISponsorContentModalForm,
  IUserRequestPayload,
} from '../../interface';

export function* editGeneralInformationGenerator({
  payload,
}: PayloadAction<IGeneralInformationModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.PATCH_UPDATE_GROUP.path.replace(':id', id),
        method: API.PATCH_UPDATE_GROUP.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editGeneralInformationSucceeded({ ...payload }));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}
export function* editAccountTypeGenerator({ payload }: PayloadAction<IAccountTypeModalForm>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.PATCH_UPDATE_GROUP.path.replace(':id', id),
        method: API.PATCH_UPDATE_GROUP.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editAccountTypeSucceeded({ ...payload }));
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}

export function* editSponsorContentGenerator({
  payload,
}: PayloadAction<ISponsorContentModalForm>): any {
  try {
    const id = yield select(selectId);
    const { sponsorContent } = payload;
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.PUT_SPONSOR_CONTENTS.path.replace(':id', id),
        method: API.PUT_SPONSOR_CONTENTS.method,
      },
      sponsorContent,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editSponsorContentSucceeded({ ...payload }));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}
export function* editActiveGenerator({ payload }: PayloadAction<IActive>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.PATCH_UPDATE_GROUP.path.replace(':id', id),
        method: API.PATCH_UPDATE_GROUP.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editActiveSucceeded(payload.isActive));
    } else {
      yield put(editGroupActions.editActiveFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editActiveFailed());
  }
}

export function* editExpertsGenerator({ payload }: PayloadAction<IExpertItemData[]>): any {
  try {
    const id = yield select(selectId);
    const entId = yield select(selectEntId);
    const experts = payload.map((i) => i.id);

    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_UPDATE_GROUP_EXPERTS.path.replace(':id', id),
        method: API.POST_UPDATE_GROUP_EXPERTS.method,
      },
      {
        groupId: id,
        expertIds: experts,
        entId,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editGroupActions.editSuccess());
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}
export function* deleteExpertGenerator({ payload }: PayloadAction<string>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.DELETE_GROUP_EXPERT.path.replace(':id', id).replace(':expertId', payload),
        method: API.DELETE_GROUP_EXPERT.method,
      },
      {
        groupId: id,
        expertId: payload,
      },
      true,
    );
    if (response.statusCode === 202) {
      yield put(editGroupActions.deleteExpertSucceeded(payload));
    } else {
      yield put(editGroupActions.deleteExpertFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteExpertFailed());
  }
}
export function* editSessionsGenerator({ payload }: PayloadAction<ISessionItemData[]>): any {
  try {
    const id = yield select(selectId);
    const sessions: IUpdateGroupExpertResourcesDto = {
      groupId: id,
      addResources: [],
    };
    const resources = new Map<string, string[]>();

    payload.map((session) => {
      const { expertId, id: sessionId } = session;

      if (resources.has(expertId)) {
        return resources.get(expertId)?.push(sessionId);
      }
      return resources.set(expertId, [sessionId]);
    });

    resources.forEach((resourceIds, expertId) => {
      const itemDetails: ItemDetailsMap = {
        expertId,
        resourceType: 'session',
        resourceIds,
      };

      sessions.addResources.push(itemDetails);
    });

    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.path.replace(':id', id),
        method: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.method,
      },
      sessions,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editSuccess());
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}
export function* hideSessionsGenerator({
  payload,
}: PayloadAction<{ expertId: string; sessionId: string; isVisible: boolean }>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.VISIBILITY_GROUP_SESSION.path
          .replace(':id', id)
          .replace(':expertId', payload.expertId)
          .replace(':resourceId', `${payload.sessionId}`),
        method: API.VISIBILITY_GROUP_SESSION.method,
      },
      { isVisible: payload.isVisible, groupId: id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.hideSessionSucceeded(payload));
    } else {
      yield put(editGroupActions.hideSessionFailed());
    }
  } catch (error) {
    yield put(editGroupActions.hideSessionFailed());
  }
}
export function* deleteSessionGenerator({
  payload,
}: PayloadAction<{ sessionId: string; expertId: string }>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.DELETE_GROUP_SESSION.path
          .replace(':id', id)
          .replace(':resourceId', payload.sessionId)
          .replace(':expertId', payload.expertId),
        method: API.DELETE_GROUP_SESSION.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.deleteSessionSucceeded(payload));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.deleteSessionFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteSessionFailed());
  }
}

export function* editContentGenerator({ payload }: PayloadAction<ISessionItemData[]>): any {
  try {
    const id = yield select(selectId);
    const contents: IUpdateGroupExpertResourcesDto = {
      groupId: id,
      addResources: [],
    };
    const resources = new Map<string, string[]>();

    payload.map((session) => {
      const { expertId, id: contentId } = session;

      if (resources.has(expertId)) {
        return resources.get(expertId)?.push(contentId);
      }
      return resources.set(expertId, [contentId]);
    });

    resources.forEach((resourceIds, expertId) => {
      const itemDetails: ItemDetailsMap = {
        expertId,
        resourceType: 'content',
        resourceIds,
      };

      contents.addResources.push(itemDetails);
    });

    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.path.replace(':id', id),
        method: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.method,
      },
      contents,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editSuccess());
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}

export function* hideContentGenerator({
  payload,
}: PayloadAction<{ expertId: string; contentId: string; isVisible: boolean }>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.VISIBILITY_GROUP_CONTENT.path
          .replace(':id', id)
          .replace(':expertId', payload.expertId)
          .replace(':resourceId', `${payload.contentId}`),
        method: API.VISIBILITY_GROUP_CONTENT.method,
      },
      { isVisible: payload.isVisible, groupId: id },
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.hideContentSucceeded(payload));
    } else {
      yield put(editGroupActions.hideSessionFailed());
    }
  } catch (error) {
    yield put(editGroupActions.hideSessionFailed());
  }
}

export function* deleteContentGenerator({
  payload,
}: PayloadAction<{ contentId: string; expertId: string }>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.DELETE_GROUP_CONTENT.path
          .replace(':id', id)
          .replace(':resourceId', payload.contentId)
          .replace(':expertId', payload.expertId),
        method: API.DELETE_GROUP_CONTENT.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.deleteContentSucceeded(payload));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.deleteContentFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteContentFailed());
  }
}

// articles
export function* editArticlesGenerator({ payload }: PayloadAction<IArticleItemData[]>): any {
  try {
    const id = yield select(selectId);
    const articles: IUpdateGroupExpertResourcesDto = {
      groupId: id,
      addResources: [],
    };
    const resources = new Map<string, string[]>();

    payload.map((article) => {
      const { author, id: articleId } = article;

      if (resources.has(author)) {
        return resources.get(author)?.push(articleId);
      }
      return resources.set(author, [articleId]);
    });

    resources.forEach((resourceIds, expertId) => {
      const itemDetails: ItemDetailsMap = {
        expertId,
        resourceType: 'article',
        resourceIds,
      };

      articles.addResources.push(itemDetails);
    });

    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.path.replace(':id', id),
        method: API.POST_UPDATE_GROUP_EXPERT_RESOURCES.method,
      },
      articles,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.editSuccess());
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}
export function* deleteArticleGenerator({
  payload,
}: PayloadAction<{ articleId: string; expertId: string }>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.DELETE_GROUP_ARTICLE.path
          .replace(':id', id)
          .replace(':resourceId', payload.articleId)
          .replace(':expertId', payload.expertId),
        method: API.DELETE_GROUP_CONTENT.method,
      },
      payload,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.deleteArticleSucceeded(payload));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.deleteArticleFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteArticleFailed());
  }
}

export function* editUsersGenerator({ payload }: PayloadAction<string[]>): any {
  try {
    const id = yield select(selectId);
    const entId = yield select(selectEntId);

    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_GROUP_USERS.path.replace(':id', id),
        method: API.POST_GROUP_USERS.method,
      },
      {
        groupId: id,
        userEmails: payload,
        status: 'Accepted',
        entId,
      },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editGroupActions.getGroup());
      yield put(editGroupActions.getUsers({
        page: 0,
        limit: 25,
        keyword: '',
        status: 'New Request',
      }));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.editFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}

export function* deleteUsersGenerator({ payload }: PayloadAction<string>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.DELETE_GROUP_USER.path.replace(':id', id).replace(':userId', payload),
        method: API.DELETE_GROUP_USER.method,
      },
      { emails: [payload] },
      true,
    );
    if (response.statusCode === 201) {
      yield put(editGroupActions.getGroup());
      yield put(editGroupActions.deleteUserSucceeded(payload));
      yield put(editGroupActions.closeModal());
    }
    if (response.statusCode === 207) {
      const errors = response.data?.errors ?? [];
      // eslint-disable-next-line array-callback-return
      yield all(
        errors.map((error: { message?: string }) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          put(
            coreSliceActions.setMessage({
              message: error?.message ?? 'Something went wrong',
              success: false,
            }),
          )),
      );
      yield put(editGroupActions.getGroup());
      yield put(editGroupActions.deleteUserSucceeded(payload));
      yield put(editGroupActions.closeModal());
    } else {
      yield put(editGroupActions.deleteUserFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteUserFailed());
  }
}

export function* getGroupGenerator(): any {
  try {
    const id = yield select(selectId);
    const { currency } = yield select(selectGeneralInformation);
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GROUP.path}/${id}`, method: API.GET_GROUP.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const preparedCurrency = currency ?? 'AUD';
      const subscriptionFee = yield call(
        currencyFormat,
        response.data.subscriptionFee,
        response.data.currency ?? 'AUD',
        preparedCurrency,
      );
      const formattedData = yield {
        ...response.data,
        subscriptionFee,
      };
      yield put(editGroupActions.getGroupSucceeded(formattedData));
    } else {
      yield put(editGroupActions.getGroupFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getGroupFailed());
  }
}

export function* getSessionsGenerator({ payload }: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const groupId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_SESSIONS_BY_ENT.path}/${id}?type=${[
          SessionType.liveClass,
          SessionType.course,
          SessionType.digitalSession,
          SessionType.liveSession,
        ]}&keyword=${payload.keyword}&publicOnly=true&page=${payload.page}&limit=${payload.limit
        }&audienceType=${SessionAudienceTypes.public}&status=${payload?.status || 'All'
        }&group=${groupId}&withoutDeleted=${payload.withoutDeleted || 'true'}`,
        method: API.GET_SESSIONS_BY_ENT.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getSessionsSucceeded(response.data));
    } else {
      yield put(editGroupActions.getSessionsFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getSessionsFailed());
  }
}

export function* getContentGenerator({ payload }: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const groupId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_SESSIONS_BY_ENT.path}/${id}?type=${SessionType.content}&keyword=${payload.keyword
        }&publicOnly=true&page=${payload.page}&limit=${payload.limit}&audienceType=${SessionAudienceTypes.public
        }&status=${payload?.status || 'All'}&group=${groupId}&withoutDeleted=${payload.withoutDeleted || 'true'
        }`,
        method: API.GET_SESSIONS_BY_ENT.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getContentSucceeded(response.data));
    } else {
      yield put(editGroupActions.getContentFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getContentFailed());
  }
}

export function* getArticlesGenerator({ payload }: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const groupId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_ARTICLES_BY_GROUP.path}/${id}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&group=${groupId}`,
        method: API.GET_ARTICLES_BY_GROUP.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getArticlesSucceeded(response.data));
    } else {
      yield put(editGroupActions.getArticlesFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getArticlesFailed());
  }
}

export function* getExpertsGenerator({ payload }: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const groupId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_GROUP_EXPERTS.path.replace(':id', groupId)}?keyword=${payload.keyword}&page=${payload.page
        }&limit=${payload.limit}&category=${payload?.category || 'All'}&status=${payload?.status || 'All'
        }&withDeleted=false&skipGroupExperts=false`,
        method: API.GET_GROUP_EXPERTS.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getExpertsSucceeded(response.data));
    } else {
      yield put(editGroupActions.getExpertsFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getExpertsFailed());
  }
}

export function* getFilteredSessionsGenerator({
  payload,
}: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const groupId = yield select(selectId);
    const expertIds = yield select(selectExpertIds);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_SESSIONS_BY_ENT.path}/${id}/${[...expertIds]}?type=${[
          SessionType.liveClass,
          SessionType.course,
          SessionType.digitalSession,
          SessionType.liveSession,
        ]}&keyword=${payload.keyword}&publicOnly=true&page=${payload.page}&limit=${payload.limit
        }&audienceType=${SessionAudienceTypes.public}&status=${payload?.status || 'All'
        }&withoutDeleted=${payload.withoutDeleted || 'true'}&ignoreAlreadyAssignedGroupId=${groupId}`,
        method: API.GET_SESSIONS_BY_ENT.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getFilteredSessionsSucceeded(response.data));
    } else {
      yield put(editGroupActions.getFilteredSessionsFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getFilteredSessionsFailed());
  }
}
export function* getFilteredContentGenerator({
  payload,
}: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectEntId);
    const groupId = yield select(selectId);
    const expertIds = yield select(selectExpertIds);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_SESSIONS_BY_ENT.path}/${id}/${[...expertIds]}?type=${SessionType.content
        }&keyword=${payload.keyword}&publicOnly=true&page=${payload.page}&limit=${payload.limit
        }&audienceType=${SessionAudienceTypes.public}&status=${payload?.status || 'All'
        }&withoutDeleted=${payload.withoutDeleted || 'true'}&ignoreAlreadyAssignedGroupId=${groupId}`,
        method: API.GET_SESSIONS_BY_ENT.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getFilteredContentSucceeded(response.data));
    } else {
      yield put(editGroupActions.getFilteredContentFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getFilteredContentFailed());
  }
}
export function* getFilteredExpertsGenerator({
  payload,
}: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_GROUP_EXPERTS.path.replace(':id', id)}?keyword=${payload.keyword}&page=${payload.page
        }&limit=${payload.limit}&category=${payload?.category || 'All'}&status=${payload?.status || 'All'
        }&withDeleted=false&skipGroupExperts=true`,
        method: API.GET_GROUP_EXPERTS.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getFilteredExpertsSucceeded(response.data));
    } else {
      yield put(editGroupActions.getFilteredExpertsFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getFilteredExpertsFailed());
  }
}

export function* getFilteredArticlesGenerator({
  payload,
}: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const expertIds = yield select(selectExpertIds);
    const groupId = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_ARTICLES_BY_ENT.path}/?experts=${[...expertIds]}&keyword=${payload.keyword
        }&page=${payload.page}&limit=${payload.limit}&ignoreAlreadyAssignedGroupId=${groupId}`,
        method: API.GET_ARTICLES_BY_ENT.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getFilteredArticlesSucceeded(response.data));
    } else {
      yield put(editGroupActions.getFilteredArticlesFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getFilteredArticlesFailed());
  }
}

export function* deleteGroupGenerator(): any {
  try {
    const id = yield select(selectId);
    const entId = yield select(selectEntId);

    const response: IRestApiResponse = yield call(request, API.DELETE_GROUP, { id }, true);
    if (response.statusCode === 202) {
      yield put(editGroupActions.deleteGroupSucceeded());
      window.location.pathname = `/ent-account/edit/${entId}/groups`;
    } else {
      yield put(editGroupActions.deleteGroupFailed());
    }
  } catch (error) {
    yield put(editGroupActions.deleteGroupFailed());
  }
}

export function* handleGroupUserRequestGenerator({
  payload,
}: PayloadAction<IUserRequestPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      {
        path: API.POST_HANDLE_USER_REQUEST.path
          .replace(':id', payload.groupId)
          .replace(':userId', payload.userId),
        method: API.POST_HANDLE_USER_REQUEST.method,
      },
      {
        ...payload,
      },
    );
    if (response.statusCode === 202) {
      yield put(editGroupActions.handleGroupUserRequestSucceeded({ ...payload }));
      yield put(editGroupActions.getGroup());
    } else {
      yield put(editGroupActions.handleGroupUserRequestFailed());
    }
  } catch (error) {
    yield put(editGroupActions.editFailed());
  }
}

export function* getFilteredUsersGenerator({
  payload,
}: PayloadAction<IPaginateFilterPayload>): any {
  try {
    const id = yield select(selectId);
    const response: IRestApiResponse = yield call(
      request,
      {
        path: `${API.GET_GROUP_USERS.path.replace(':id', id)}?keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&status=${payload.status}`,
        method: API.GET_GROUP_USERS.method,
      },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(editGroupActions.getUsersSucceeded(response.data));
    } else {
      yield put(editGroupActions.getUsersFailed());
    }
  } catch (error) {
    yield put(editGroupActions.getUsersFailed());
  }
}

export function* editGroupSaga() {
  yield all([
    takeLatest(editGroupActions.editGeneralInformation.type, editGeneralInformationGenerator),
    takeLatest(editGroupActions.editAccountType.type, editAccountTypeGenerator),
    takeLatest(editGroupActions.editSponsorContent.type, editSponsorContentGenerator),
    takeLatest(editGroupActions.editActive.type, editActiveGenerator),
    takeLatest(editGroupActions.editExperts.type, editExpertsGenerator),
    takeLatest(editGroupActions.deleteExpert.type, deleteExpertGenerator),
    takeLatest(editGroupActions.editSessions.type, editSessionsGenerator),
    takeLatest(editGroupActions.editContent.type, editContentGenerator),
    takeLatest(editGroupActions.editArticles.type, editArticlesGenerator),
    takeLatest(editGroupActions.editUsers.type, editUsersGenerator),
    takeLatest(editGroupActions.deleteUser.type, deleteUsersGenerator),
    takeLatest(editGroupActions.hideSession.type, hideSessionsGenerator),
    takeLatest(editGroupActions.deleteSession.type, deleteSessionGenerator),
    takeLatest(editGroupActions.hideContent.type, hideContentGenerator),
    takeLatest(editGroupActions.deleteContent.type, deleteContentGenerator),
    takeLatest(editGroupActions.deleteArticle.type, deleteArticleGenerator),
    takeLatest(editGroupActions.deleteGroup.type, deleteGroupGenerator),
    takeLatest(editGroupActions.getGroup.type, getGroupGenerator),
    takeLatest(editGroupActions.getSessions.type, getSessionsGenerator),
    takeLatest(editGroupActions.getContent.type, getContentGenerator),
    takeLatest(editGroupActions.getArticles.type, getArticlesGenerator),
    takeLatest(editGroupActions.getExperts.type, getExpertsGenerator),
    takeLatest(editGroupActions.getFilteredExperts.type, getFilteredExpertsGenerator),
    takeLatest(editGroupActions.getFilteredSessions.type, getFilteredSessionsGenerator),
    takeLatest(editGroupActions.getFilteredContent.type, getFilteredContentGenerator),
    takeLatest(editGroupActions.getFilteredArticles.type, getFilteredArticlesGenerator),
    takeLatest(editGroupActions.getUsers.type, getFilteredUsersGenerator),
    takeLatest(editGroupActions.handleGroupUserRequest.type, handleGroupUserRequestGenerator),
  ]);
}

export default editGroupSaga;
