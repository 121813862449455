import * as yup from 'yup';

const onboardContentModalFormValidation = yup.object().shape({
  landingPageContent: yup.object().shape({
    tagLine: yup.string().trim().max(100).label('Tag line'),
    title: yup.string().trim().max(55).label('Title'),
    body: yup.string().trim().max(300).label('Body Copy'),
  }),
  groupLandingPageContent: yup.object().shape({
    title: yup.string().trim().max(80).label('Title'),
    body: yup.string().trim().max(500).label('Body Copy'),
  }),
  expertLandingPageContent: yup.object().shape({
    title: yup.string().trim().max(80).label('Title'),
    body: yup.string().trim().max(500).label('Body Copy'),
  }),
  sessionLandingPageContent: yup.object().shape({
    title: yup.string().trim().max(80).label('Title'),
    body: yup.string().trim().max(500).label('Body Copy'),
  }),
  articleLandingPageContent: yup.object().shape({
    title: yup.string().trim().max(80).label('Title'),
    body: yup.string().trim().max(500).label('Body Copy'),
  }),
  contentLandingPageContent: yup.object().shape({
    title: yup.string().trim().max(80).label('Title'),
    body: yup.string().trim().max(500).label('Body Copy'),
  }),
});
export default onboardContentModalFormValidation;
