import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import chargesFormValidation from '../../validation/charges-from-validation';
import ChargeForm from '../charges-form';

import SuccessfulView from '../successful-view';
import { createEntAccActions } from '../../../../slice';
import { CreateEntAccStep, IAddChargesForm } from '../../../../interface';
import { selectChargeForm, selectLoader, selectStatus } from '../../../../selector';

const ChargesView = () => {
  const dispatch = useDispatch();
  const ChargesForm = useSelector(selectChargeForm);
  const loading = useSelector(selectLoader);
  const methods = useForm<IAddChargesForm>({
    defaultValues: {
      adminFee: ChargesForm.adminFee,
      stripePlatformRegion: '',
    },
    resolver: yupResolver(chargesFormValidation),
    shouldFocusError: true,
  });
  const status = useSelector(selectStatus);
  const createAnotherEntAcc = () => {
    dispatch(createEntAccActions.reset());
  };
  const onSubmit: SubmitHandler<IAddChargesForm> = useCallback(async (data) => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(createEntAccActions.createEntAcc({
          ...data,
          resolve,
          reject,
        }));
      });
    } catch (error: any) {
      if (error.includes('email')) {
        dispatch(createEntAccActions.setStatus(CreateEntAccStep.generalInformation));
      }
    }
  }, [dispatch]);

  return (
    <Grid container justifyContent="center">
      { !(status === 3) ? (
        <Grid xs={12} item>
          <FormProvider {...methods}>
            <ChargeForm onSubmit={onSubmit} loading={loading} />
          </FormProvider>
        </Grid>
      ) : (
        <Grid xs item>
          <SuccessfulView createAnotherEntAcc={createAnotherEntAcc} />
        </Grid>
      )}

    </Grid>
  );
};

export default ChargesView;
