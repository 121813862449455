import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend } from 'chart.js';
import React, { useMemo } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  ErrorMessage,
  PrimaryButton,
  PrimaryLoadingButton,
  PrimaryTooltip,
  SliderInput,
} from 'components';
import { selectIsSlocoachSuperUser } from 'base/auth/selectors';
import { Info } from '@mui/icons-material';
import { IOnboardChargesModalForm } from '../../../../../interface';
import { selectStripeAccountCompleted, selectStripeConnectUrl, selectStripePlatformRegion } from '../../../../../selector';
import { onboardEntAccActions } from '../../../../../slice';

Chart.register(ArcElement, Legend);

interface OnboardChargesModalFormProps {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
}
const percentageMarks = [
  { value: 0, label: '0' },
  { value: 100, label: '100' },
];

const OnboardChargesModalForm = ({ onSubmit, loading }: OnboardChargesModalFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<IOnboardChargesModalForm>();
  const dispatch = useDispatch();
  const isSuperAdmin = useSelector(selectIsSlocoachSuperUser);
  const stripeAccountCompleted = useSelector(selectStripeAccountCompleted);
  const stripePlatformRegion = useSelector(selectStripePlatformRegion);
  const stripeConnectUrl = useSelector(selectStripeConnectUrl);
  const anchorRef = React.useRef<HTMLAnchorElement>(null);

  const watchForEntClient = watch('charges.forEntClient');
  const watchForExpert = watch('charges.forExpert');
  const watchAdminFee = watch('charges.adminFee');
  const total = useMemo(
    () => watchForEntClient + watchForExpert + watchAdminFee,
    [watchForEntClient, watchForExpert, watchAdminFee],
  );

  React.useEffect(() => {
    if (total > 100) {
      if (isSuperAdmin) {
        setValue('charges.forEntClient', 0);
        setValue('charges.adminFee', 0);
      } else {
        setValue('charges.forEntClient', 0);
        setValue('charges.adminFee', watchAdminFee);
        setValue('charges.forExpert', 100 - watchAdminFee);
      }
    }
  }, [watchForExpert]);

  React.useEffect(() => {
    if (total > 100) {
      if (isSuperAdmin) {
        setValue('charges.forExpert', 0);
        setValue('charges.adminFee', 0);
      } else {
        setValue('charges.forExpert', 0);
        setValue('charges.adminFee', watchAdminFee);
        setValue('charges.forEntClient', 100 - watchAdminFee);
      }
    }
  }, [watchForEntClient]);

  React.useEffect(() => {
    if (total > 100) {
      if (isSuperAdmin) {
        setValue('charges.forExpert', 0);
        setValue('charges.forEntClient', 0);
      }
    }
  }, [watchAdminFee]);

  const chartData = {
    labels: [
      `${watchForEntClient}% Commission for enterprise client`,
      `${watchForExpert}% Commission for expert`,
      `${watchAdminFee}% Admin fee`,
    ],

    datasets: [
      {
        data: [watchForEntClient, watchForExpert, watchAdminFee],

        backgroundColor: ['#F7C85C', '#79D4F1', '#BA80C6'],
        borderWidth: 1,
      },
    ],
  };
  React.useEffect(() => {
    if (stripeConnectUrl !== '') {
      anchorRef.current?.click();
    }
  }, [stripeConnectUrl]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="form-item">Charges</h3>
      <div className="form-item">
        <h4>Commission for the enterprise client</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="charges.forEntClient"
          render={({ field }) => (
            <SliderInput
              max={100}
              min={0}
              step={1}
              defaultValue={0}
              marks={percentageMarks}
              {...field}
              label="This will be the amount that you charge for each purchase"
            />
          )}
        />
        <ErrorMessage>{errors?.charges?.forEntClient?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Commission for expert</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="charges.forExpert"
          render={({ field }) => (
            <SliderInput
              max={100}
              min={0}
              step={1}
              defaultValue={0}
              marks={percentageMarks}
              {...field}
              label="This will be the amount that the expert charge for each purchase"
            />
          )}
        />
        <ErrorMessage>{errors?.charges?.forExpert?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Admin fee</h4>
      </div>
      <div className="form-item">
        <Controller
          control={control}
          name="charges.adminFee"
          render={({ field }) => (
            <SliderInput
              max={100}
              min={0}
              step={1}
              defaultValue={0}
              marks={percentageMarks}
              {...field}
              label="This will be the amount that SLOCOACH get for all transactions"
              disabled={!isSuperAdmin}
            />
          )}
        />
        <ErrorMessage>{errors?.charges?.adminFee?.message}</ErrorMessage>
      </div>
      <div className="form-item">
        <h4>Charges breakdown</h4>
      </div>
      <div className="form-item">
        <div
          style={{
            border: '1px solid #E5E5E5',
            width: '100%',
            padding: '1rem',
          }}
        >
          <div style={{ width: '70%' }}>
            <Doughnut
              data={chartData}
              options={{
                cutout: 55,
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                    position: 'right',
                    labels: {
                      usePointStyle: true,
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-item">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography variant="h3">Stripe connect</Typography>
          <PrimaryTooltip title="All earnings transferred to your Stripe connected bank account will have Stripe Fees automatically deducted">
            <IconButton>
              <Info color="primary" />
            </IconButton>
          </PrimaryTooltip>
        </Box>
        <Typography variant="body1" className="form-item">
          Slocoach uses Stripe to get you paid quickly and keep your personal and payment
          information secure. Thousands of companies around the world trust Stripe to process
          payments for their users. Set up a Stripe account to get paid with Slocoach.
          {' '}
        </Typography>
        <a href={stripeConnectUrl} ref={anchorRef}>
          {' '}
        </a>
        <PrimaryButton
          loading={loading}
          onClick={() => {
            dispatch(onboardEntAccActions.getStripConnectUrl());
          }}
        >
          {!stripeAccountCompleted ? 'Set up stripe' : 'Update stripe details'}
        </PrimaryButton>
      </div>
      <div className="form-item">
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography variant="h3">
            Stripe platform region:
            {' '}
            <b>{stripePlatformRegion}</b>
          </Typography>
        </Box>
      </div>
      {/* <div style={{ marginTop: '1rem' }}>
        <h3 className="form-item">Bank account details</h3>
        <div className="form-item">
          <Controller
            control={control}
            name="bankDetails.accountName"
            render={({ field }) => <PrimaryTextField {...field} label="Account Name" />}
          />
          <ErrorMessage>{errors?.bankDetails?.accountName?.message}</ErrorMessage>
        </div>
        <div className="form-item">
          <Controller
            control={control}
            name="bankDetails.accountNumber"
            render={({ field }) => (
              <PrimaryTextField
                type="number"
                {...field}
                label="Account number"
              />
            )}
          />
          <ErrorMessage>{errors?.bankDetails?.accountNumber?.message}</ErrorMessage>
        </div>
        <div className="form-item">
          <Controller
            control={control}
            name="bankDetails.branchName"
            render={({ field }) => <PrimaryTextField {...field}
            label="BSB / Sort Code / Routing" />}
          />
          <ErrorMessage>{errors?.bankDetails?.branchName?.message}</ErrorMessage>
        </div>
        <div className="form-item">
          <Controller
            control={control}
            name="bankDetails.bankName"
            render={({ field }) => <PrimaryTextField {...field} label="Bank name" />}
          />
          <ErrorMessage>{errors?.bankDetails?.bankName?.message}</ErrorMessage>
        </div>
      </div> */}

      <div>
        <PrimaryLoadingButton loading={loading} size="large" type="submit" variant="contained">
          Save & continue
        </PrimaryLoadingButton>
      </div>
    </form>
  );
};
export default OnboardChargesModalForm;
