const API = {
  GET_GROUP_BY_ENT: {
    path: '/2.0/groups',
    method: 'GET',
  },
  DELETE_GROUP: {
    path: '/2.0/groups/:id',
    method: 'DELETE',
  },
  PATCH_UPDATE_GROUP: {
    path: '/2.0/groups/:id',
    method: 'PATCH',
  },
};

export default API;
