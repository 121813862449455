import {
  call, put, select, all, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { request } from 'utils/request';
import { IRestApiResponse } from 'interfaces';
import { CategoryResourceType } from 'config';
import { createArticleActions } from './slice';
import API from '../../constant';

import { ICreateArticleForm, IPaginateFilterPayload } from './interface';
import {
  selectActive, selectArticleId, selectEntId, selectIsAuthorActive,
} from './selector';
import { ArticleDocument } from '../../interface';

const formateRequest = async (
  payload:ICreateArticleForm,
) => ({
  title: payload.title,
  author: payload.author?.id,
  url: payload?.url,
  highlight: payload?.highlight,
  lead: payload?.lead,
  body: payload?.body,
  assignedGroups: payload?.assignedGroups?.map((group) => (group.uuid)),
  highlightMedia: payload?.highlightMedia?._id || null,
  products: payload?.products?.map((session) => (session.id)),
  contents: payload?.contents?.map((session) => (session.id)),
  experts: payload?.experts?.map((expert) => (expert.id)),
});

function* assignCategories(resourceId: string, categories: string[]) {
  yield call(
    request,
    API.POST_ASSIGN_CATEGORIES,
    {
      resourceId,
      categoryId: categories,
      resourceType: CategoryResourceType.ARTICLE,
    },
  );
}

function* getCategories(resourceId: string) {
  const response: IRestApiResponse = yield call(
    request,
    {
      path: `${API.GET_CATEGORIES.path}?resourceId=${resourceId}&resourceType=${CategoryResourceType.ARTICLE}`,
      method: API.GET_CATEGORIES.method,
    },
    null,
    true,
  );

  return response;
}

export function* createArticleGenerator({ payload }:PayloadAction<ICreateArticleForm>): any {
  try {
    const entId = yield select(selectEntId);
    const isActive = yield select(selectActive);
    const formattedRequest = yield call(formateRequest, payload);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_CREATE_ARTICLE,
      {
        entId,
        isActive,
        ...formattedRequest,
      },
      true,
    );
    const { categories } = payload;
    if (response.statusCode === 201) {
      yield assignCategories(response.data?.createArticle?.id, categories);
      yield put(createArticleActions.createArticleSucceeded());
    } else {
      yield put(createArticleActions.createArticleFailed());
    }
  } catch (error) {
    yield put(createArticleActions.createArticleFailed());
  }
}
export function* editArticleGenerator({ payload }:PayloadAction<ICreateArticleForm>): any {
  try {
    const id = yield select(selectArticleId);
    const isActive = yield select(selectActive);
    const isAuthorActive = yield select(selectIsAuthorActive);
    const formattedRequest = yield call(formateRequest, payload);
    const response: IRestApiResponse = yield call(
      request,
      API.POST_UPDATE_ARTICLE,
      {
        id,
        ...(isAuthorActive && { isActive }),
        ...formattedRequest,
      },
      true,
    );
    const { categories } = payload;
    if (response.statusCode === 201) {
      yield assignCategories(id, categories);
      yield put(createArticleActions.editArticleSucceeded());
    } else {
      yield put(createArticleActions.editArticleFailed());
    }
  } catch (error) {
    yield put(createArticleActions.editArticleFailed());
  }
}

export function* getArticleGenerator({ payload }:PayloadAction<string>): any {
  try {
    const id = payload;
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_ARTICLE.path}/${id}`, method: API.GET_ARTICLE.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      const categories: IRestApiResponse = yield getCategories(id);
      const article = response.data as ArticleDocument;
      const item: ICreateArticleForm = {
        title: article.title,
        author: article.author,
        highlight: article.highlight,
        highlightMedia: article.highlightMedia,
        lead: article.lead,
        body: article.body,
        assignedGroups: article.assignedGroups || [],
        products: article.products || [],
        experts: article.experts || [],
        contents: article.contents || [],
        url: article.url,
        categories: categories?.data?.map((i: { id: string; name: string; }) => i.id),
      };
      yield put(createArticleActions.editActive(article.isActive));
      yield put(createArticleActions.setForPreview(item));
    } else {
      yield put(createArticleActions.getArticleFailed());
    }
  } catch (error) {
    yield put(createArticleActions.getArticleFailed());
  }
}
export function* getGroupGenerator({ payload }:PayloadAction<IPaginateFilterPayload>): any {
  try {
    const response: IRestApiResponse = yield call(
      request,
      { path: `${API.GET_GROUPS_BY_EXPERT.path}/?expertId=${payload.expertId}&keyword=${payload.keyword}&page=${payload.page}&limit=${payload.limit}&groupType=${payload.groupType}`, method: API.GET_GROUPS_BY_EXPERT.method },
      null,
      true,
    );
    if (response.statusCode === 200) {
      yield put(createArticleActions.getFilteredGroupsSucceeded(response.data));
    } else {
      yield put(createArticleActions.getFilteredGroupsFailed());
    }
  } catch (error) {
    yield put(createArticleActions.getFilteredGroupsFailed());
  }
}

export function* createArticleSaga() {
  yield all([takeLatest(
    createArticleActions.createArticle.type,
    createArticleGenerator,
  ), takeLatest(
    createArticleActions.editArticle.type,
    editArticleGenerator,
  ), takeLatest(
    createArticleActions.getArticle.type,
    getArticleGenerator,
  ), takeLatest(
    createArticleActions.getFilteredGroups.type,
    getGroupGenerator,
  ),
  ]);
}

export default createArticleSaga;
