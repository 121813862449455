/* eslint-disable react/require-default-props */
import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { Close } from '@mui/icons-material';
import { PrimaryOutlinedButton, PrimaryTable } from 'components';
import { MonitorIcon } from 'assets/icons';
import '../index.scss';
import { IGroupAvatarWithAction } from 'pages/dashboard/components/ent-account/components/edit-account/components/groups/interface';

interface SelectedGroupTableProps {
  items: IGroupAvatarWithAction[],
  handelRemove: (id: string) => void
  editMode?: boolean

}

const SelectedGroupTable = ({ items, handelRemove, editMode = false }: SelectedGroupTableProps) => {
  type Row = typeof items[number];

  const columns = React.useMemo<GridColumns<Row>>(
    () => [
      {
        field: 'title',
        headerName: 'Group Title',
        headerClassName: 'selected-item-column',
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Grid item sx={{ mr: 0.7 }}>
                  <Avatar
                    src={params.row.bannerImgUrl}
                    sx={{
                      width: 30,
                      height: 30,
                      mr: 1,
                      backgroundColor: 'background.default',
                      color: 'primary.main',
                      borderWidth: 1,
                      borderColor: 'primary.light',
                      borderStyle: 'solid',
                    }}
                  >
                    <MonitorIcon sx={{ fontSize: 16 }} />
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography variant="h4" sx={{ fontWeight: '400' }}>{params.value}</Typography>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        ),
      },
      {
        field: 'isAdded',
        headerName: '',
        headerAlign: 'left',
        headerClassName: 'selected-item-column',
        align: 'left',
        flex: 0.5,
        renderCell: (params) => (
          <div>
            {!editMode && (
              <Grid container alignItems="center" sx={{ color: '#E93E3E' }}>
                <Close />
                <PrimaryOutlinedButton onClick={() => { handelRemove(params.row.uuid); }}>
                  Remove
                </PrimaryOutlinedButton>
              </Grid>
            )}
          </div>

        ),
      },

    ],
    [],
  );

  return (
    <div className="form-item">
      <PrimaryTable
        className="selected-table"
        disableSelectionOnClick
        getRowId={(row) => row.uuid}
        rows={items}
        columns={columns}
        loading={false}
        hideFooter
        headerHeight={30}
      />
    </div>

  );
};

export default SelectedGroupTable;
