/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { SessionAudienceTypes } from 'config';
import { ClosableModal, PrimaryButton } from 'components';
import { Typography } from '@mui/material';
import SessionFeeForm from '../session-fees-form';
import sessionFeeFormValidation from '../../validation/session-fees-form-validation';
import { ISessionFeeForm } from '../../../../interface';
import { selectSessionFee, selectSessionInformation } from '../../../../selector';
import { createSessionActions } from '../../../../slice';

interface SessionFeeViewProps {
  editMode: boolean
}
export default function SessionFeeView({ editMode }: SessionFeeViewProps) {
  const dispatch = useDispatch();
  const sessionFee = useSelector(selectSessionFee);
  const { sessionType, audienceType, isObserverAllowed } = useSelector(selectSessionInformation);

  const [modal, setModal] = React.useState(false);
  const handleOpen = () => setModal(true);
  const handleClose = () => setModal(false);

  const methods = useForm<ISessionFeeForm>({
    defaultValues: {
      ...sessionFee,
    },
    resolver: yupResolver(sessionFeeFormValidation),
    context: { sessionType, audienceType, isObserverAllowed },
    shouldFocusError: true,
  });

  const currency = methods.watch('currency');

  function isMinimumChargeFeeApplied(session: ISessionFeeForm) {
    const minimumFee = 0.5;

    const getFee = (fee: number, discount: number) => fee - (fee * (discount / 100));

    let allFeesValid = true;

    if (audienceType === SessionAudienceTypes.subscription) {
      const regularFeeValid = Number(session.fee) === 0
        || getFee(Number(session.fee), Number(session.discount)) >= minimumFee;

      const subscriberFeeValid = Number(session.subscriberFee) === 0
        || getFee(Number(session.subscriberFee), Number(session.subscriberDiscount)) >= minimumFee;

      allFeesValid = regularFeeValid && subscriberFeeValid;
    } else {
      allFeesValid = Number(session.fee) === 0
        || getFee(Number(session.fee), Number(session.discount)) >= minimumFee;
    }

    // If observer is allowed, check observer fees
    if (isObserverAllowed && allFeesValid) {
      if (audienceType === SessionAudienceTypes.subscription) {
        // Check both regular observer fee and subscriber observer fee
        const regularObserverFeeValid = Number(session.observerFee) === 0
          || getFee(Number(session.observerFee), Number(session.observerDiscount)) >= minimumFee;

        const subscriberObserverFeeValid = Number(session.subscribeObserverFee) === 0
          // eslint-disable-next-line max-len
          || getFee(Number(session.subscribeObserverFee), Number(session.subscribeObserverDiscount)) >= minimumFee;

        allFeesValid = allFeesValid && regularObserverFeeValid && subscriberObserverFeeValid;
      } else {
        // Check regular observer fee for non-subscription
        allFeesValid = allFeesValid && (Number(session.observerFee) === 0
          || getFee(Number(session.observerFee), Number(session.observerDiscount)) >= minimumFee);
      }
    }

    return allFeesValid;
  }

  const onSubmit:
  SubmitHandler<ISessionFeeForm> = React.useCallback((data) => {
    if (!isMinimumChargeFeeApplied(data)) {
      handleOpen();
      return;
    }

    if (editMode) {
      dispatch(createSessionActions.editSession(data));
    } else {
      dispatch(createSessionActions.createSession(data));
    }
  }, [dispatch]);

  return (
    <div>
      <FormProvider {...methods}>
        <ClosableModal
          title=""
          open={modal}
          onClose={handleClose}
          subTitle=""
        >
          <Typography variant="h3" textAlign="center">
            The minimum charge fee is 0.5
            {' '}
            {currency}
            . Please update the fee or discount to meet the minimum charge fee.
          </Typography>
          <div style={{ textAlign: 'center', marginTop: '1rem' }}>
            <PrimaryButton onClick={handleClose}>Ok</PrimaryButton>
          </div>
        </ClosableModal>
        <SessionFeeForm
          onSubmit={onSubmit}
          editMode={editMode}
        />
      </FormProvider>
    </div>
  );
}
