const API = {
  // Group Management
  GET_GROUP: {
    path: '/2.0/groups',
    method: 'GET',
  },
  PATCH_UPDATE_GROUP: {
    path: '/2.0/groups/:id',
    method: 'PATCH',
  },
  PUT_SPONSOR_CONTENTS: {
    path: '/2.0/groups/:id/sponsor-contents',
    method: 'PUT',
  },

  DELETE_GROUP: {
    path: '/2.0/groups',
    method: 'DELETE',
  },

  // Group Experts Management
  POST_UPDATE_GROUP_EXPERTS: {
    path: '/2.0/groups/:id/experts',
    method: 'POST',
  },
  DELETE_GROUP_EXPERT: {
    path: '/2.0/groups/:id/experts/:expertId',
    method: 'DELETE',
  },

  DELETE_GROUP_SESSION: {
    path: '/group/:id/experts/:expertId/session/:resourceId',
    method: 'DELETE',
  },
  VISIBILITY_GROUP_SESSION: {
    path: '/group/:id/experts/:expertId/session/:resourceId/visibility',
    method: 'PATCH',
  },
  DELETE_GROUP_CONTENT: {
    path: '/group/:id/experts/:expertId/content/:resourceId',
    method: 'DELETE',
  },
  VISIBILITY_GROUP_CONTENT: {
    path: '/group/:id/experts/:expertId/content/:resourceId/visibility',
    method: 'PATCH',
  },
  DELETE_GROUP_ARTICLE: {
    path: '/group/:id/experts/:expertId/article/:resourceId',
    method: 'DELETE',
  },

  // member management
  GET_GROUP_USERS: {
    path: '/2.0/groups/:id/users',
    method: 'GET',
  },
  POST_GROUP_USERS: {
    path: '/2.0/groups/:id/users',
    method: 'POST',
  },
  POST_HANDLE_USER_REQUEST: {
    path: '/2.0/groups/:id/users/:userId/invite',
    method: 'POST',
  },
  DELETE_GROUP_USER: {
    path: '/2.0/groups/:id/users/:userId',
    method: 'DELETE',
  },

  // Resource Management
  POST_UPDATE_GROUP_EXPERT_RESOURCES: {
    path: '/2.0/groups/:id/resources',
    method: 'POST',
  },
  GET_GROUP_EXPERTS: {
    path: '/2.0/groups/:id/experts',
    method: 'GET',
  },
  GET_SESSIONS_BY_ENT: {
    path: '/session/search',
    method: 'GET',
  },
  GET_ARTICLES_BY_ENT: {
    path: '/article/groups',
    method: 'GET',
  },
  GET_ARTICLES_BY_GROUP: {
    path: '/article/search',
    method: 'GET',
  },
};

export default API;
