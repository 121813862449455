/* eslint-disable no-underscore-dangle */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalStates } from '../../interface';
import {
  IActivateGroup,
  IGroupRequest, IOpenModal, IPaginateFilterPayload, ViewGroupState,
} from './interface';

export const initialState: ViewGroupState = {
  stateLoading: false,
  groups: [],
  total: 0,
  loading: false,
};

export const viewGroupSlice = createSlice({
  name: 'feature/ent-acc/edit-ent-acc/group/view-group',
  initialState,
  reducers: {
    getGroups(state: ViewGroupState, _action: PayloadAction<IPaginateFilterPayload>) {
      state.stateLoading = true;
    },
    getGroupsSucceeded(state: ViewGroupState, action: PayloadAction<IGroupRequest>) {
      state.groups = action.payload?.groups?.map((group) => (
        { ...group, modalState: ModalStates.close })) || [];
      state.total = action.payload?.total || 0;
      state.stateLoading = false;
    },
    getGroupsFailed(state: ViewGroupState) {
      state.stateLoading = false;
    },
    openModal(state: ViewGroupState, action: PayloadAction<IOpenModal>) {
      const temp = state.groups;
      temp.forEach((group) => {
        if (group.uuid === action.payload.id) {
          group.modalState = action.payload.state;
        }
      });
      state.groups = [...temp];
    },
    closeModal(state: ViewGroupState, action: PayloadAction<string>) {
      const temp = state.groups;
      temp.forEach((group) => {
        if (group.uuid === action.payload) {
          group.modalState = ModalStates.close;
        }
      });
      state.groups = [...temp];
    },
    editActiveGroup(state: ViewGroupState, _action: PayloadAction<IActivateGroup>) {
      state.loading = true;
    },
    editActiveGroupSucceeded(state: ViewGroupState, action: PayloadAction<IActivateGroup>) {
      state.loading = false;
      const temp = state.groups;
      temp.forEach((group) => {
        if (group.uuid === action.payload.id) {
          group.isActive = action.payload.isActive;
        }
      });
      state.groups = [...temp];
    },
    editActiveGroupFailed(state: ViewGroupState) {
      state.loading = false;
    },
    deleteGroup(state: ViewGroupState, _action: PayloadAction<string>) {
      state.loading = true;
    },
    deleteGroupSucceeded(state: ViewGroupState, action: PayloadAction<string>) {
      const temp = state.groups.filter((group) => group.uuid !== action.payload);
      state.groups = [...temp];
      state.loading = false;
    },
    deleteGroupFailed(state: ViewGroupState) {
      state.loading = false;
    },

  },
});

export const { actions: viewGroupActions } = viewGroupSlice;
