import * as yup from 'yup';

const chargeFormValidation = yup.object().shape({
  adminFee: yup.number().transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
    .required('Admin fee is a required field')
    .positive('The admin fee should be a positive number')
    .integer('Admin fee should be a number without decimal points')
    .min(0, 'Admin fee should be greater than 0')
    .typeError('Admin fee should be a number without decimal points')
    .max(100, 'Admin fee must be 100 or less'),
  stripePlatformRegion: yup.string().trim().required('Stripe platform region is a required field'),
});
export default chargeFormValidation;
