/* eslint-disable max-len */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../../../../interfaces';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state['feature/ent-acc/edit-ent-acc/user/edit-user'] || initialState;

export const selectEditUser = createSelector([selectDomain], (EditUserState) => EditUserState);
export const selectStateLoader = createSelector([selectDomain], (EditUserState) => EditUserState.stateLoading);
export const selectLoader = createSelector([selectDomain], (EditUserState) => EditUserState.loading);
export const selectId = createSelector([selectDomain], (EditUserState) => EditUserState.id);
export const selectModalState = createSelector([selectDomain], (EditUserState) => EditUserState.modal);
export const selectGeneralInformation = createSelector([selectDomain], (EditUserState) => EditUserState.generalInformation);
export const selectPurchasedSessions = createSelector([selectDomain], (EditUserState) => EditUserState.sessionsPurchased);
export const selectBadges = createSelector([selectDomain], (EditUserState) => EditUserState.badges);
export const selectSubscribedExperts = createSelector([selectDomain], (EditUserState) => EditUserState.subscriptedExperts);
export const selectSubscribedGroups = createSelector([selectDomain], (EditUserState) => EditUserState.subscriptedGroups);
export const selectFollowingExperts = createSelector([selectDomain], (EditUserState) => EditUserState.followingExperts);
export const selectFollowingGroups = createSelector([selectDomain], (EditUserState) => EditUserState.followingGroups);
export const selectIsDeleted = createSelector([selectDomain], (EditUserState) => EditUserState.isDeleted);
export const selectNotificationSettings = createSelector([selectDomain], (EditUserState) => EditUserState.notificationSettings);
